import React from 'react';
import './HeaderInfo.css'
import {Link} from "react-router-dom";
const HeaderInfo = () => {

   function closeNavbar  (){
        const navbarCollapse = document.getElementById("navbarNav");
        navbarCollapse.classList.remove("show");
    }

    return (
        <div>
            <nav className="navbar navbar-expand-lg bg-primary p-0 nav-color navbar-collapse">
                <div className="container ms-lg-2 ">
                    <button className="navbar-toggler white" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" fill="currentColor"
                             className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd"
                                  d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                    </button>
                    <div className="collapse navbar-collapse header-text" id="navbarNav" onClick={closeNavbar}>
                        <ul className="navbar-nav header-text">
                            <li className="nav-item btn btn-outline-secondary">
                                <Link className="nav-link  white" aria-current="page" to="/">Главная</Link>
                            </li>
                            <li className="nav-item btn btn-outline-secondary">
                                <Link className="nav-link  white" to="/volunteers">Наши волонтёры</Link>
                            </li>
                            <li className="nav-item btn btn-outline-secondary">
                                <Link className="nav-link  white" to="/actual">Актуальные проекты</Link>
                            </li>
                            <li className="nav-item btn btn-outline-secondary">
                                <Link className="nav-link  white" to="/archive">Архив проектов</Link>
                            </li>
                            <li className="nav-item btn btn-outline-secondary">
                                <Link className="nav-link  white" to="/contacts">Контакты и документы</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
};

export {HeaderInfo};