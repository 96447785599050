import React from 'react';
import './Load.css'
const Load = () => {
    return (
        <div className="container justify-content-center">
            <div className="loader-container">
                <div className="spinner"></div>
            </div>
        </div>
    );
};

export default Load;