import React from 'react';
import './Contact.css'

const Contact = () => {

    const docPath = 'https://s3.timeweb.cloud/3c497961-superlager/sodeystvie/Устав 2004.doc'

    return (
        <div className='m-0 align-items-center'>
            <h1>Контактная информация</h1>
            <div className="text-center m-0">
                <p><a className="email text-center a-footer text-contact" href="mailto:sodeystvie@gmail.com">📧
                    sodeystvie@gmail.com</a></p>
            </div>
            <div className="text-center mb-2 text-contact m-0">
                <span className="phone">📞 +7(916)157-11-31</span>
            </div>
            <div className="text-center mb-2 text-contact m-0">
                <h2>Правовая информация</h2>
            </div>
            <div className="text-center mb-2 text-contact m-0">
                <a href={docPath} className="document" target="_blank">Устав организации</a>
            </div>


        </div>
    );
};

export default Contact;